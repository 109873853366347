<template>
  <div>
    <div class="pb-3">
      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') }}</span>
      </b-button>
      <!-- refresh button -->
    </div>
    <div>
      <!-- List page filter -->
      <pass-list-filter class="d-inline-block w-100" @search="setFilter" v-model="filter"></pass-list-filter>
      <!-- List page filter -->

      <b-form-group :label="$t('TRAVELS_SHOW_CLOSED') | capitalize">
        <b-checkbox v-model="filter.showClosed"></b-checkbox>
      </b-form-group>
    </div>

<!--    <b-form-timepicker v-model="testTime"></b-form-timepicker>-->
<!--    <b-form-datepicker v-model="testDate"></b-form-datepicker>-->

    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('TRAVELS')})  | capitalize }}</strong>
    </div>
    <b-container fluid v-else class="p-0">
      <b-row>
        <b-col>
          <span>Hora del equipo: {{currentTime}}</span>
        </b-col>
      </b-row>
      <b-row class="p-0" cols="2" cols-lg="4" cols-xl="4" cols-md="4">
        <b-col :class="(travel.status !== 'TRAVEL_STATUS_CLOSED') || filter.showClosed ? 'py-1 m-0 p-1' : 'd-none'" v-for="travel in travels" :key="travel.id">
          <travel-pass @delete-travel="deleteTravel" class="w-100" :time="currentTime" v-if="(travel.status !== 'TRAVEL_STATUS_CLOSED') || filter.showClosed" :travel="travel"></travel-pass>
        </b-col>
        <b-col v-if="travels.length <= 0" class="text-center">
          <h2>{{$t('NO_TRAVELS_FOUND') | capitalize}}</h2>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {SendsMessages} from "@/mixins";
import Travels from "@/travels";
import PassListFilter from "@/travels/TravelListFilter";
import moment from "moment";
import TravelPass from "@/travels/TravelPass";
import Travel from "@/travels/index";

export default {
  name: "TravelList",
  props: ['selectMode', 'currentlySelected'],
  components: {TravelPass, PassListFilter},
  mixins: [SendsMessages],
  async mounted() {
    await this.refresh();
    this.interval = setInterval(() => {
      this.currentTime = this.testTime ? moment(moment(this.testDate ?? new Date()).format("DD/MM/YYYY") + this.testTime, "DD/MM/YYYY HH:mm:ss") : new Date()
      console.log(this.currentTime)
    }, 30000);
  },
  methods: {
    async deleteTravel(travel) {
      try {
        if(confirm(this.$t('TRAVEL_DELETE_WARNING', {number: travel.number}))) {
          this.loading = true;
          await Travel.deleteById(travel.id);
          this.sendMessage('TRAVEL_DELETED_OK', {number: travel.number});
          const idx = this.travels.map(x => x.id).indexOf(travel.id);
          this.travels.splice(idx, 1);
        }
      } catch (e) {
        console.error(e);
        this.sendMessage('TRAVEL_DELETED_ERROR', {number: travel.number}, e);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      try {
        this.loading = true;
        this.filter.date = this.filter.date ? this.filter.date : moment(this.date).format("YYYY-MM-DD");
        this.filter.sortBy = 'routeName';
        this.travels = await this.controller.getTravels(this.filter);
      } catch (e) {
        this.sendError('ERROR_LOADING_PASSES', {}, e);
      } finally {
        this.loading = false;
      }
    },
    setFilter(filter) {
      this.filter = filter;
      this.refresh();
    },
  },
  data() {
    return {
      currentTime: new Date(),
      testTime: undefined,
      testDate: undefined,
      date: new Date(),
      selectedCount: 0,
      loading: false,
      interval: null,
      travels: [],
      filter: {date: moment().format("YYYY-MM-DD"), showClosed: false},
    }
  },
  computed: {
    controller() {
      return Travels;
    },
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
