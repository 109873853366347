<template>
  <div>
    <b-form class="mb-2">
      <b-container class="p-0" fluid>
        <b-row cols="1" cols-xl="4" cols-lg="4" cols-md="4" cols-sm="1" cols-xs="1">
          <b-col class="p-1">
            <div >
              <b-form-datepicker @input="search"
                                 v-model="filter.date"
                                 right
                                 locale="es-MX"
                                 :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                 class="w-100"
                                 :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('PASS')})"
                                 id="search-input"></b-form-datepicker>
            </div>
          </b-col>
          <b-col class="p-1">
            <turn-single-selector undefined-means="FORMS_SELECT_ONE_TURN"  value-type="id"
                                  @input="search" v-model="filter.turnId"></turn-single-selector>
          </b-col>
          <b-col class="p-1">
            <company-single-selector undefined-means="FORMS_SELECT_ONE_COMPANY"  value-type="id"
                                     @input="search" v-model="filter.companyId"></company-single-selector>
          </b-col>
          <b-col class="p-1">
            <bus-single-selector undefined-means="FORMS_SELECT_ONE_BUS"  value-type="id" @input="search"
                                 v-model="filter.busId"></bus-single-selector>
          </b-col>
          <b-col class="p-1">
            <driver-single-selector undefined-means="FORMS_SELECT_ONE_DRIVER"  value-type="id"
                                    @input="search" v-model="filter.driverId"></driver-single-selector>
          </b-col>
          <b-col class="p-1">
            <travel-type-single-selector undefined-means="FORMS_SELECT_ONE_TRAVEL_TYPE"  value-type="id"
                                         @input="search" v-model="filter.travelTypeId"></travel-type-single-selector>
          </b-col>
          <b-col class="p-1">
            <b-select v-model="filter.travelDirection" @input="search" >
              <b-select-option :value="undefined">{{ $t('FORMS_SELECT_ONE_DIRECTION') | capitalize }}</b-select-option>
              <b-select-option value="TRAVEL_TO">{{ $t('TRAVEL_TO') | capitalize }}</b-select-option>
              <b-select-option value="TRAVEL_FROM">{{ $t('TRAVEL_FROM') | capitalize }}</b-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {CapitalFilter, VModel} from "@/mixins";
import TurnSingleSelector from "@/turns/TurnSingleSelector";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import moment from "moment";
import BusSingleSelector from "@/buses/BusSingleSelector";
import DriverSingleSelector from "@/drivers/DriverSingleSelector";
import TravelTypeSingleSelector from "@/travel-types/TravelTypeSingleSelector";

export default {
  name: "TravelListFilter",
  components: {
    TravelTypeSingleSelector,
    BusSingleSelector, DriverSingleSelector, CompanySingleSelector, TurnSingleSelector
  },
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {
        showClosed: false,
        date: moment().format("YYYY-MM-DD")
      }
    }
  },
  methods: {
    search() {
      this.filter.date = moment(this.filter.date).format("YYYY-MM-DD")
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
