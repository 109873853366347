import http from "@/http";

const Travels = {
    findAll: async function (ctx) {
        if (ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sort = ctx.sort || 'date';
            const sortDesc = ctx.sortDesc ? 'desc' : 'asc';
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/travels/search/all", {params});
            return resp.data._embedded.travels;
        } else {
            const resp = await http("/travels/search/all");
            return resp.data._embedded.travels;
        }
    },
    changeStatus(id, status, comments) {
        return http.patch("/travels/" + id + "/changeStatus", {status, comments});
    },
    changeDriver(id, driver, comments) {
        return http.patch("/travels/" + id + "/changeDriver", {driver, comments});
    },
    changeBus(id, bus, comments) {
        return http.patch("/travels/" + id + "/changeBus", {bus, comments});
    },
    nextStatus(id) {
        return http.patch("/travels/" + id + "/nextStatus", {});
    },
    getNextStatus(id) {
        return http.get("/travels/" + id + "/nextStatus", {});
    },
    async getTravels(filter) {
        const params = filter;
        filter.size = 10000;
        filter.sort = 'routeName';
        const resp = await http("/travels/search/byDate", {params});
        return resp.data._embedded.travels.sort((a, b) => a.routeName > b.routeName ? 1 : -1);
        //return resp.data._embedded.travels;
    },
    async update(id, data) {
        return http.patch("/travels/" + id, data);
    },
    deleteById(id) {
        return http.delete("travels/" + id);
    }
};

export default Travels;