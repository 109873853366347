import http from "@/http";
import eventBus from "@/events";

const TravelType = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'name') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/travelTypes/search/all", {params});
            eventBus.$emit('LIST_COUNT_TRAVEL_TYPE_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.travelTypes;
        } else {
            const resp = await http("/travelTypes/search/all");
            eventBus.$emit('LIST_COUNT_TRAVEL_TYPE_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.travelTypes;
        }
    },
    create: function(data) {
        return http.post('/travelTypes', data);
    },
    update: function (id, data) {
        return http.patch('/travelTypes/' + id, data);
    },
    findById(id) {
        return http.get('/travelTypes/' + id);
    },
    enable(id) {
        return http.patch('/travelTypes/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/travelTypes/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/travelTypes/' + id)
    },
    nameExists(name) {
        return http.get("/travelTypes/exists/name", {params: {name: name}}).then(x => {
            return !x.data;
        });
    }
};

export default TravelType;
