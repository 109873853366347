<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('DRIVERS')})  | capitalize }}</strong>
    </div>
    <b-select
        v-else
        id="driver-input" type="text"
        :state="state"
        :value="content"
        @change="handleInput" class="w-100">
      <b-select-option :value="undefined">{{
          $t(undefinedMeans, {
            gender: 'o',
            catalog: $t('TURNS')
          }) | capitalize
        }}
      </b-select-option>
      <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : valueType === 'object' ? item : item._links.self.href">{{ $t(item.fullName) }}
      </b-select-option>
    </b-select>
    <b-form-invalid-feedback id="driver-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Driver from "@/drivers/index";

export default {
  name: "DriverSingleSelector",
  props: ['state', 'errors', 'companyId', 'valueType', 'undefinedMeans'],
  mixins: [VModel, SendsMessages],
  methods: {
    async loadDrivers(companyId) {
      try {
        this.loading = true;
        this.items = await Driver.findAll({perPage: 1000, filter: {status: true, companyId: companyId}, sortBy: 'name'});
      } catch (e) {
        this.sendError('ERROR_LOADING_CATALOG', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.loadDrivers();
    //const that = this;
    // eventBus.$on('TRAVEL_FORM_COMPANY_CHANGE', async function(companyId) {
    //   that.loadDrivers(companyId);
    // });
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
