<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PASS')})  | capitalize }}</strong>
    </div>
    <b-card :style="`background-color: ${background}`" v-else>
      <template #header>
        <h5 class="text-right">{{ $t('TRAVEL_NUMBER_LABEL') | capitalize }}: {{ travel.number }}</h5>
      </template>
      <b-card-body @click="goNextStatus()" class="cursor">
        <div class="text-center">
          <h5>{{ travel.routeName }}</h5>
          <h6 class="d-none d-sm-block">{{ travel.company }}</h6>
          <h6 class="d-none d-sm-block">{{ travel.turn }}</h6>
          <h6 class="d-none d-sm-block">{{ travel.driver }}</h6>
          <h6 class="d-none d-sm-block">{{ travel.bus }}</h6>
          <h6 class="d-none d-sm-block">{{ travel.travelType.name }}</h6>
          <h6 class="d-none d-sm-block">{{ $t(travel.travelDirection) | capitalize }}</h6>
          <h6 class="d-none d-sm-block">{{$t('TRAVEL_PARENT_LABEL')}}: {{ travel.parentNumber }}</h6>
          <h6 v-if="debug">{{ currentStatus ? currentStatus.name : 'no current status' }}</h6>
          <h6 v-if="debug">{{ minuteDiff }}</h6>
          <h6 v-if="debug">{{ background }}</h6>
          <h6 v-if="debug">{{ travel.nextDay }}</h6>
          <h6 v-if="debug">{{momentNow}}</h6>
          <h6 class="d-none d-sm-block">{{passMoment}}</h6>
        </div>
      </b-card-body>
      <template #footer>
        <div>
          <b-icon-currency-dollar variant="success" v-b-tooltip :title="$t('TRAVEL_WILL_BE_CHARGED') | capitalize"
                                  v-if="travel.paid"></b-icon-currency-dollar>
          <b-icon-x-circle variant="warning" v-b-tooltip :title="$t('TRAVEL_WILL_NOT_BE_CHARGED') | capitalize"
                           v-else></b-icon-x-circle>
          <b-icon-x-circle variant="info" v-b-tooltip :title="$t('TRAVEL_NEXT_DAY', {date: travel.dayBefore}) | capitalize"
                           v-if="travel.nextDay"></b-icon-x-circle>
          <b-icon-check v-if="travel.status === 'TRAVEL_STATUS_CLOSED'"></b-icon-check>
          <b-icon-arrow-right v-else></b-icon-arrow-right>
          {{ $t(travel.status) | capitalize }}
        </div>
        <b-button class="mr-1" v-if="travel.editable || hasPermission('TRAVEL_PROGRAMMING_CLOSE_TRAVELS_PERMISSION')" @click="openOptionsModal" variant="primary">{{ $t('PASS_EDIT_LABEL')  | capitalize }}</b-button>
        <b-button v-if="travel.editable || hasPermission('TRAVEL_PROGRAMMING_CLOSE_TRAVELS_PERMISSION')" @click="deleteTravel" variant="danger">{{ $t('PASS_DELETE_LABEL')  | capitalize }}</b-button>
      </template>
    </b-card>
    <b-modal @show="setTravelVars" :title="$t('EDIT_TRAVEL_TITLE') | capitalize" hide-footer :id="modalId">
      <app-messages></app-messages>
      <b-form @submit.prevent="doChanges">
        <b-form-group :label="$t('PASS_STATUS_LABEL') | capitalize">
          <b-select v-model="status">
            <b-select-option v-for="event in travel.events" :key="event.name" :value="event.name">{{ $t(event.name) }}
              {{ event.travelDate }}
            </b-select-option>
            <b-select-option :value="'TRAVEL_STATUS_CLOSED'">{{ $t('TRAVEL_STATUS_CLOSED') }}</b-select-option>
          </b-select>
        </b-form-group>

        <b-form-group :label="$t('PASS_DRIVER_LABEL') | capitalize">
          <driver-single-selector v-model="driver" value-type="id"></driver-single-selector>
        </b-form-group>

        <b-form-group :label="$t('PASS_BUS_LABEL') | capitalize">
          <bus-single-selector v-model="bus" value-type="id"></bus-single-selector>
        </b-form-group>

        <b-form-group :label="$t('PASS_TRAVEL_TYPE_LABEL') | capitalize">
          <travel-type-single-selector v-model="travelType" value-type="id"></travel-type-single-selector>
        </b-form-group>

        <b-form-group :label="$t('PASS_PAID_LABEL')">
          <b-checkbox v-model="paid"></b-checkbox>
        </b-form-group>

        <b-form-group
            :label="$t('PASS_COMMENT_LABEL') | capitalize">
          <b-textarea v-model="comments"></b-textarea>
        </b-form-group>

        <b-button type="submit" :disabled="$v.$invalid" variant="primary">{{ $t('FORMS_SAVE_LABEL') | capitalize }}
        </b-button>
      </b-form>
    </b-modal>
  </div>

</template>

<script>
import {CapitalFilter, Debug, SendsMessages} from "@/mixins";
import DriverSingleSelector from "@/drivers/DriverSingleSelector";
import BusSingleSelector from "@/buses/BusSingleSelector";
import {requiredIf} from "vuelidate/lib/validators";
import AppMessages from "@/layout/AppMessages";
import Travel from "@/travels";
import moment from "moment";
import TravelTypeSingleSelector from "@/travel-types/TravelTypeSingleSelector";
import Token from "@/token";

export default {
  name: "PassItem",
  components: {TravelTypeSingleSelector, AppMessages, BusSingleSelector, DriverSingleSelector},
  props: ['travel', 'time'],
  mixins: [Debug, CapitalFilter, SendsMessages],
  mounted() {
    this.refreshStatus(new Date());
    this.paid = this.travel ? this.travel.paid : false;
    this.comments = this.travel ? this.travel.observations : '';
  },
  watch: {
    time(val) {
      this.displayTime = val;
      this.refreshStatus(val);
    },
    travel() {
      this.refreshStatus(new Date());
    }
  },
  methods: {
    hasPermission(permission) {
      return Token.hasPermission(permission);
    },
    setTravelVars() {
      this.status = this.travel.status;
      this.driver = this.travel.driverId;
      this.bus = this.travel.busId;
      this.travelType = this.travel.travelTypeId;
      this.paid = this.travel.paid;
      this.comments = this.travel.observations;
    },
    refreshStatus(time) {
      if (this.currentStatus) {
        this.momentNow = moment(time);
        const now = this.momentNow;
        this.passMoment = moment(this.currentStatus.travelDate);
        const pass = this.passMoment;
        this.minuteDiff = now.diff(pass, 'minutes');
        if (this.minuteDiff > -1) {
          this.background = "#F00";
        } else {
          this.background = this.currentStatus.color;
        }
      } else {
        this.minuteDiff = 0;
        this.background = '#FFF';
      }
    },
    async deleteTravel() {
      this.$emit('delete-travel', this.travel);
    },
    async doChanges() {
      try {
        this.loading = true;
        console.log('original travel')
        console.log(this.travel);
        const resp = await this.controller.update(this.travel.id, {
          status: this.status,
          driver: this.driver,
          bus: this.bus,
          travelType: this.travelType,
          paid: this.paid,
          comments: this.comments
        });
        const updated = resp.data;
        console.log('updated travel')
        console.log(updated);
        this.travel.status = updated.status;
        this.travel.driver = updated.driver.name;
        this.travel.driverId = updated.driver.id;
        this.travel.bus = updated.bus.floatNumber;
        this.travel.busId = updated.bus.id;
        this.travel.travelType = updated.travelType.name;
        this.travel.travelTypeId = updated.travelType.id;
        this.travel.paid = updated.paid;
        this.travel.observations = updated.observations;

        this.sendMessage('PASS_CHANGED_OK', this.travel);
      } catch (e) {
        this.sendError('ERROR_CHANGING_PASS', {}, e);
      } finally {
        this.loading = false;
        this.closeOptionsModal();
      }
    },
    async getNextStatus() {
      const resp = await Travel.getNextStatus(this.travel.id);
      return resp.data;
    },
    async goNextStatus() {
      try {
        this.loading = true;
        const nextStatus = await this.getNextStatus();
        let resp = null;
        if (nextStatus === 'TRAVEL_STATUS_CLOSED') {
          if (confirm(this.$t('CONFIRM_FINAL_PASS_MESSAGE'))) {
            resp = await this.controller.nextStatus(this.travel.id);
          } else {
            return;
          }
        } else {
          resp = await this.controller.nextStatus(this.travel.id);
        }
        this.travel.status = resp.data;
        this.sendMessage('PASS_BUS_OK_CHANGED', this.travel);
      } catch (e) {
        this.sendError('ERROR_CHANGING_PASS', {}, e);
      } finally {
        this.loading = false;
      }
    },
    openOptionsModal() {
      this.$bvModal.show(this.modalId);
    },
    closeOptionsModal() {
      this.$bvModal.hide(this.modalId);
    }
  },
  data() {
    return {
      momentNow: undefined,
      passMoment: undefined,
      loading: false,
      allowToChange: false,
      minuteDiff: 10,
      background: '#FFF',
      events: [],
      comments: '',
      status: null,
      driver: null,
      bus: null,
      displayTime: null,
      travelType: null,
      paid: false
    }
  },
  computed: {
    controller() {
      return Travel;
    },
    modalId() {
      return `${this.travel.id}-change-status`;
    },
    currentStatus() {
      return this.travel.events.find(x => x.name === this.travel.status);
    },
  },
  validations: {
    status: {
      required: requiredIf(function (nestedModel) {
        return nestedModel.allowToChange === 1;
      })
    },
    driver: {
      required: requiredIf(function (nestedModel) {
        return nestedModel.allowToChange === 2;
      })
    },
    bus: {
      required: requiredIf(function (nestedModel) {
        return nestedModel.allowToChange === 3;
      })
    },
  }
}
</script>

<style scoped>
.cursor:hover {
  cursor: pointer;
}

.card-body {
  padding: 0 !important;
}
</style>
